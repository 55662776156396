<template>
  <div>
    <div id="element-to-print">
      <div id="prescription" class="w-full">
        <div class="vx-row w-full">
          <feather-icon
            @click="back()"
            :icon="$vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
            style="width: 30px"
          >
          </feather-icon>
          <span class="text-2xl mb-2"> {{ $t("Prescription") }} </span>
        </div>
        <vx-card class="p-5 w-full prescription">
          <div class="lg:w-1/2">
            <h2 class="font-bold mb-2">
              {{ $t("Dr") }}.{{ Model.DoctorName }}
            </h2>
          </div>
          <div class="vx-row">
            <div class="vx-col lg:w-1/4 w-full">
              <p v-if="Model.Name">
                {{ $t("PrescriptionName") }}:{{
                  Model.Name
                }}
              </p>
            </div>
            <div class="lg:w-1/2"></div>
            <div class="vx-col lg:w-1/4 w-full mt-1">
              <p>{{ $t("Locallicensenumber") }} : {{ Model.DoctorLocalLicenseNumber }}</p>
            </div>
          </div>
          <div class="vx-row mb-4 mt-2">
            <div class="vx-col lg:w-1/4 w-full">
              <p v-if="Model.Name">
                {{ $t("Speciality") }} :
                {{ Model.Name }}
              </p>
            </div>
            <div class="lg:w-1/2"></div>
            <div class="vx-col lg:w-1/4 w-full mt-1">
              <p>
                {{ $t("DrAdress") }}:{{ Model.Address }}
              </p>
            </div>
          </div>

          <fieldset class="w-full">
            <legend class="w-full">{{ $t("Prescription") }}</legend>
            <table class="mt-2 w-full" style="margin: 0.5rem">
              <tr>
                <th>{{ $t("medicine") }}</th>
                <th>{{ $t("ActiveIngredients") }}</th>
                <th>{{ $t("Dossage") }}</th>
                <th>{{ $t("Duration") }}</th>
              </tr>
              <tr v-for="item in Model.PatientReservatientSessionMedicals" :key="item.ID">
                <td>
                  <vs-input v-if="item.MedicineName" disabled v-model="item.MedicineName">
                  </vs-input>
                </td>
                <td>
                  <vs-input v-if="item.ActiveIngredients" disabled v-model="item.ActiveIngredients">
                  </vs-input>
                </td>
                <td>
                  <vs-input disabled v-model="item.Dose"> </vs-input>
                </td>
                <td>
                  <vs-input disabled v-model="item.DurationInDay"> </vs-input>
                </td>
              </tr>
            </table>
          </fieldset>
          <div class="vs-row mt-8 mb-6">
            <div>
              <span class="ml-2"> {{ $t("PatientDiagnosis") }} </span>
              <vs-textarea v-if="Model.Diagnosis" class="w-full" disabled v-model="Model.Diagnosis"> </vs-textarea>
               <p v-else class="warning">{{$t('NoDiagnosisHasbeenadded')}}</p>
            </div>
          </div>
          <div class="flex justify-between mt-8 mb-6">
            <div>
              <span>{{ $t("Doctorsignature") }} :</span>
                <p style="color:blue;font-style: italic">Dr. {{Model.DoctorName }}</p>

            </div>
          </div>

        </vx-card>
      </div>
    </div>
    <div dir="ltr">
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        filename="Prescription"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="800px"
        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="html2Pdf"
      >
        <section slot="pdf-content" dir="ltr">
          <vx-card class="p-10" dir="ltr">
            <div>
              <h2 class="font-bold">
                Dr. {{ Model.DoctorName }}
              </h2>
            </div>
            <div class="flex justify-between mx-4">
              <div></div>
              <div>
                <span> Local license number : {{ Model.DoctorLocalLicenseNumber }} </span>
              </div>
            </div>
            <div class="flex justify-between mx-4 mb-2">
              <div>
                <p v-if="Model.Name">
                  Prescription Name :{{
                    Model.Name
                  }}
                </p>
                <p>Dr Adress :{{ Model.Address }}</p>
              </div>
              <div>
                <span v-if="Model.Name">
                  Speciality : {{ Model.Name }}
                </span>
              </div>
            </div>

            <fieldset>
              <legend class="">Prescription</legend>
              <table style="width: 100%" class="mt-2 p-1">
                <tr>
                  <th class="text-lg">medicine</th>
                  <th class="text-lg">Active Ingredients</th>
                  <th class="text-lg">Dossage</th>
                  <th class="text-lg">Duration</th>
                </tr>
                <tr
                  class="mx-auto"
                  v-for="item in Model.PatientReservatientSessionMedicals"
                  :key="item.ID"
                >
                  <td>
                    <vs-input
                      v-if="item.MedicineName"
                      disabled
                      v-model="item.MedicineName"
                    >
                    </vs-input>
                  </td>
                   <td>
                    <vs-input
                      v-if="item.ActiveIngredients"
                      disabled
                      v-model="item.ActiveIngredients"
                    >
                    </vs-input>
                  </td>
                  <td>
                    <vs-input class="" disabled v-model="item.Dose"> </vs-input>
                  </td>
                  <td>
                    <vs-input class="" disabled v-model="item.DurationInDay"> </vs-input>
                  </td>
                </tr>
              </table>
            </fieldset>
            <div class="flex justify-between mt-8 mb-6">
              <div>
                <span class="ml-2"> Doctor Diagnosis </span>
                   <vs-textarea style="width: 300%" class="" disabled v-model="Model.Diagnosis">
                </vs-textarea>
              </div>
            </div>
            <div class="flex justify-between mt-8 mb-6">
              <div>
                <span>Doctor signature :</span>
                <p style="color:blue;font-style: italic; font-size:10px !important;">Dr. {{Model.DoctorName }}</p>
              </div>
            </div>
          </vx-card>

          <!-- PDF Content Here -->
        </section>
      </vue-html2pdf>
    </div>

    <div style="justify-content: center; display: flex" class="mt-10">
      <vs-button @click="ConvertToPDF" icon-pack="icon icon-download" icon="feather"
        >{{ $t("DownloadPrescription") }}
      </vs-button>
    </div>
  </div>
</template>
<script>
import { domain } from "@/gloabelConstant.js";
import html2pdf from "html2pdf.js";
import VueHtml2pdf from "vue-html2pdf";
import modulePatient from "@/store/Patient/modulePatient.js";

export default {
  components: {
    VueHtml2pdf,
  },
  data() {
    return {
      CurrentPage: 0,
      CountPerPage: 4,
      Model: {},
      Search: {
        Speciality: "",
        DoctorName: "",
      },
      baseURL: domain,
    };
  },
  computed: {
    // Prescriptions() {
    //   return this.$store.state.ModelList;
    // },
  },
  methods: {
    getPrescriptionData() {
      debugger;
      this.$vs.loading();


          var Obj = {
      patientReservationSessionID:
        this.$route.params.ID,

    };

      this.$store
        .dispatch("patientList/SearchReservationSessionPrescriptions", Obj)
        .then((res) => {
          if (res.status == 200) {
            debugger;
             if(res.data.Data!=null&&res.data.Data.length>0)
                this.Model = res.data.Data[0];


            this.$vs.loading.close();
            if (this.Model == null || this.Model.length == 0) {
              this.$vs.notify({
                title: this.$t("NoData"),
                text: this.$t("NoDataToshow"),
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "warning",
              });
            }
          }
        })
        .catch(() => {
          window.showError();
        });
    },
    back() {
      this.$router.go(-1);
    },
    ConvertToPDF() {
      this.$refs.html2Pdf.generatePdf();
      // var element = document.getElementById("element-to-print");
      // debugger;
      // html2pdf(element);
    },
  },
  created() {
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    this.getPrescriptionData();
  },
};
</script>
<style>
.vue-html2pdf {
  direction: ltr;
  text-align: center;
}
#prescription .vs-button {
  margin-top: 1.9%;
  justify-content: center;
}
#prescription fieldset {
  border-collapse: separate;
  border: solid #00000029 1px;
  border-radius: 36px !important;
  -moz-border-radius: 36px;
}

#prescription .medcine {
  background: #e8e8e8;
}
#prescription table td {
  padding: 4px;
  border: 1px solid white !important;
  border-collapse: collapse;
}
#prescription .doctorImage {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
#prescription .vs-input {
  width: 90%;

  color: black !important;
}
#prescription legend {
  margin-left: 2rem;
}
#prescription table {
  border: 1px solid white !important;
  border-collapse: collapse;
  border-radius: 7px !important;
}
#prescription tr {
  border: 1px solid white !important;
  border-collapse: collapse;
}

#prescription table th {
  background-color: white !important;
  border: 1px solid white !important;
  border-collapse: collapse;
  text-align: left !important;
}
</style>
